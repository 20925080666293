export default function (Component) {
      Component.options.frontmatter = {
  "published": "2019-05-26T00:00:00.000Z",
  "tags": [
    "programming",
    "coding style"
  ],
  "image": require('./cover.jpg'),
  "title": "Comments in Code",
  "excerpt": "<p>So one more pull request got rejected for having a comment; &quot;Good code is self-documenting.&quot;, said the reviewer.</p>\n"
}
    }